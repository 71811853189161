/* Banner */

.swiper-banner {
  position: relative;
  overflow: hidden;

  .swiper-slide {
    .slider-caption-one, .slider-caption-two, .slider-caption-three {
      opacity: 0;
      transition-delay: 0.3s; } }


  .swiper-slide-active {
    .slider-caption-one, .slider-caption-two, .slider-caption-three {
      opacity: 1; } }


  .swiper-slide {

    > img {
      width: 100%;
      height: auto; }

    .slider-caption {
      h3 {
        @include font-size(20);
        color: #FFF;
        text-transform: uppercase;
        font-weight: 700;
        @include letter-spacing(600);
        margin-top: 0;
        text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
        margin-top: 0;
        margin-bottom: 30px;
        text-transform: uppercase; }


      h2 {
        @include font-size(50);
        color: #FFF;
        font-weight: 700;
        text-transform: uppercase;
        text-shadow: 0px 4px 5.88px rgba(0, 0, 0, 0.2);
        margin-top: 0;
        margin-bottom: 40px; }

      p {
        color: #FFF;
        @include line-height(26);
        margin-bottom: 50px; } } } }


.banner-next,.banner-prev {
  top: 50%;
  position: absolute;
  z-index: 500;
  color: #FFF;
  background: rgba(0,0,0, 0.7);
  height: 50px;
  width: 50px;
  transition: all 0.3s ease;
  text-align: center;
  opacity: 0;
  overflow: hidden;
  cursor: pointer;

  .next-btn, .prev-btn {
    i {
      @include font-size(40); } }

  &:hover {
    background: $color_theme; } }


.banner-next {
  right: 0%; }


.swiper-pagination {
  text-align: right;
  right: 100px;
  left: auto !important; }

.swiper-pagination-bullet {
  width: 3px;
  height: 20px;
  border-radius: 0;
  background: #FFF;
  opacity: 1;
  margin: 0 2px !important; }

.swiper-pagination-bullet-active {
  background: $color-theme;
  height: 25px; }

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0, 0.5); }


.slider-caption-one {
  text-align: center;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
  width: 100%;
  position: absolute;

  h2 {
    @include font-size(70);
    color: #FFF;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 0;
    position: relative;
    padding: 15px 0;
    display: block;
    letter-spacing: 1px;
    margin-bottom: 20px;
    // animation: zoomIn 1.3s both

    &:before {
      position: absolute;
      content: '';
      background: $color_theme;
      width: 300px;
      height: 3px;
      left: 50%;
      top: 0;
      transform: translateX(-50%); }

    &:after {
      position: absolute;
      content: '';
      background: $color_theme;
      width: 300px;
      height: 3px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%); } }

  h3 {
    font-size: 22px;
    color: #FFF;
    margin-bottom: 50px;
    letter-spacing: 1px; } }




.slider-caption-two {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);

  h4 {
    color: $color_theme;
    @include font-size(22);
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 700; }


  h2 {
    @include font-size(40);
    color: #FFF;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px; }

  p {
    color: #FFF;
    @include font-size(16);
    margin-bottom: 30px; } }

.slider-caption-three {
  position: absolute;
  left: 20%;
  top: 50%;
  transform: translateY(-50%);

  h3 {
    color: #fff;
    font-weight: 600;
    @include font-size(32);
    letter-spacing: 1px;
    margin-bottom: 30px;
    font-weight: 600; }

  h4 {
    color: #FFF;
    @include font-size(22);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px; }


  h2 {
    @include font-size(50);
    color: $color_theme;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 25px; }

  p {
    color: #ddd;
    @include font-size(15);
    margin-bottom: 30px; } }


.switcher {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 500;
  display: block;
  animation: move 2.5s infinite;
  i {
    color: #FFF;
    font-size: 30px; }

  &:hover {
    text-decoration: none; } }


.swiper-banner {
  &:hover {
    .banner-next,.banner-prev {
      opacity: 1; } } }

@keyframes move {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(25px); }
  100% {
    transform: translateY(0px); } }

