/* Portfolio */
.portfolio {
  padding-top: 60px;
  padding-bottom: 80px;
  text-align: center;
  overflow: hidden;


  .portfolioFilter {
    margin-bottom: 50px;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 10px;


      a {
        text-decoration: none;
        color: #111;
        font-size: 14px;
        padding: 6px 15px;
        border: 1px solid #eee;
        transition: all 0.3s ease-in-out;
        background: #eee;

        &.current, &:hover {
          background-color: $color_theme;
          color: #FFF;
          border-color: transparent;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); } } } } }



.portfolio_container {
  .portfolio-item {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      transition: all 0.5s ease-in-out; }

    .overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(53,152,219, .8);
      text-align: left;
      transform: scale(0);
      transition: all 0.3s ease-in-out;
      text-align: center;

      &:before, &:after {
        content: "";
        opacity: 0;
        position: absolute;
        left: 20px;
        bottom: 20px;
        right: 20px;
        top: 20px;
        z-index: 1;
        transition: opacity 0.35s ease 0s, transform 0.35s ease 0s; }

      &:before {
        border-top: 1px solid #FFF;
        border-bottom: 1px solid #FFF;
        transform: scale(0, 1); }

      &:after {
        border-left: 1px solid #FFF;
        border-right: 1px solid #FFF;
        transform: scale(1, 0); }

      .work-caption {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;

        .view {
          color: #FFF;
          display: inline-block;
          color: #FFF;
          transition: all .5s ease-in-out;
          @include font-size(20);
          height: 40px;
          width: 40px;
          padding: 5px;
          border: 1px solid #FFF;
          text-align: center;
          @include line-height(20);
          border-radius: 50%;
          margin-bottom: 100px;

          &:first-child {
            margin-right: 10px; }


          &:hover {
            background: #000;
            border-color: transparent; } }

        h2 {
          margin-top: 0;
          @include font-size(22);
          transition: all 0.5s ease-in-out;
          transform: translateY(70px) scale(0.95);
          text-transform: uppercase;

          a {
            color: #FFF;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: #111; } } }


        h3 {
          @include font-size(16);
          margin: 0;
          transition: all 0.7s ease-in-out;
          transform: translateY(60px) scale(0.95);

          a {
            color: #FFF;
            text-decoration: none;
            transition: all 0.3s ease-in-out;

            &:hover {
              color: #111; } } } } }



    &:hover {

      img {
        transform: scale(1.3); }
      .overlay {
        transform: scale(1);

        &:before {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
          transform: scale(1); }

        &:after {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.4s ease 0s, transform 0.45s ease 0.2s;
          transform: scale(1); }

        .work-caption {

          .view {
            margin-bottom: 30px; }



          h2 {
            transform: translateX(0px) scale(1); }

          h3 {
            transform: translateX(0) scale(1); } } } } } }

/* Portfolio Two */
.portfolio-bg {
  background: #f5f5f5; }

#portfolio-two {

  .grid-item {
    width: 33%;
    padding: 10px;
    float: left; } }


.portfolio_container {
  .mix {
    display: none; } }

#portfolio-two {
  .portfolio-item {
    margin: 5px; } }
