#newsletter {
  padding: 100px 0;
  text-align: center;
  background-size: cover;


  .news-wraper {
    max-width: 600px;
    margin: 0 auto;

    h3 {
      @include font-size(35);
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 30px;
      color: $color_theme; }


    form {
      border: 2px solid $color_theme;
      position: relative;
      overflow: hidden;

      input[type=email] {
        width: 100%;
        background-color: transparent;
        border: none;
        padding: 15px 125px 15px 20px;
        outline: none;
        color: #FFF; }

      button {
        background: $color_theme;
        padding: 15px 20px;
        color: #FFF;
        border: none;
        position: absolute;
        right: 0;
        top: 0;
        outline: none;
        transition: all 0.3s ease-in-out;
        text-transform: uppercase;
        font-weight: 600;

        i {
          margin-right: 10px; }

        &:hover {
          background: #FFF;
          color: #333; } } } } }
