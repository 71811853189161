#header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  background: rgba(0,0,0,0.2);
  transition: all 0.3s ease-in-out;

  .navbar {
    margin-bottom: 0;
    height: auto;
    border: none; }
  .navbar-header {
    transition: all 0.3s ease-in-out;

    .navbar-brand {
      padding: 20px 0;
      height: auto;
      transition: all 0.3s ease-in-out;
      width: 190px;

      img {
        width: 100%; }

      &.fixed-logo {
        display: none; } } }


  .navbar-nav {
    padding: 29px 0;
    transition: all 0.3s ease-in-out;

    li {
      padding: 0px 10px;
      a {
        color: #FFF;
        text-transform: uppercase;
        padding: 10px 0;
        transition: all 0.3s ease-in-out;
        position: relative;
        @include font-size(14);
        font-weight: 600;

        &:after {
          content: '';
          position: absolute;
          width: 0;
          height: 2px;
          background: $color_theme;
          left: 50%;
          right: 50%;
          bottom: 0;
          transition: all 0.5s ease-in-out; }

        &:hover, &:focus {
          background-color: transparent;
          color: $color_theme;

          &:after {
            left: 0;
            right: 0;
            width: 100%; } } }
      &.gp-search {
        position: relative;
        padding-left: 30px;

        a {
          &:after {
            display: none; } }


        &:before {
          background: #FFF;
          height: 14px;
          width: 2px;
          content: '';
          position: absolute;
          left: 10px;
          top: 12px; } }

      .child-menu {
        left: 0px;
        position: absolute;
        top: 102%;
        width: 200px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(50px);
        background: none 0px 0px repeat scroll #05263c;
        list-style: none outside none;
        margin: 0px;
        padding: 0px;
        transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition-timing-function: cubic-bezier(0.680, -0.550, 0.265, 1.550);

        li {
          padding: 10px;
          border-bottom: 1px solid #4e4e4e;
          transition: all 0.3s ease-in-out;

          a {
            display: block;
            padding: 0;
            color: #FFF !important;
            text-transform: capitalize;


            &:after {
              display: none; } }

          &:last-child {
            border-bottom: none; }

          &:hover {
            background-color: $color_theme;
            a {
              color: #FFF !important;
              display: block;
              text-decoration: none !important; } } } }

      &:hover {

        .child-menu {
          transform: translateY(28px);
          visibility: visible;
          transition: all 0.5s ease-out;
          opacity: 1;
          z-index: 9999; } } } } }
